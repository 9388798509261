export default {
  message: {
    to: "34684030966",
    text:
      "Hi Wali, I would like to talk to you about an exciting idea of mine.",
  },
  email: "mohdwali.es@gmail.com",
  socialHandles: {
    facebook: "developer.wali",
    instagram: "developer.wali",
    twitter: "developer_wali",
  },
};
